import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { ContactForm } from '../forms'
import './index.css'
import { MapForm } from '../forms/MapForm'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..

const MapFormTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content
  if(typeof window !== 'undefined' || typeof document !== 'undefined'){
    if (window.location.pathname.indexOf('admin') > 0) {
      AOS.init({
        disable: false,
      });
    } else AOS.init({disable: false});
  }

  return (
    <div>
      <section id="toppart" className='hero is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                <div data-aos="fade-right"
                 data-aos-duration="2000">
                  <h1 className='title'>
                    Contact PPE4ALL. 
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section3'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-10 is-offset-1'>
              <div className='section'> 
              <div data-aos="fade"
                 data-aos-duration="2000">
                <h2><strong>Contact Us</strong></h2> </div>
                <br></br>
                <div data-aos="fade"
                 data-aos-duration="2000">
                <p id="contactdesc"> Please note: this form is <strong> only for queries unrelated to PPE. </strong><br></br>If you would like to donate, request, make PPE or volunteer, please use the tabs above!</p>
                <br></br>
                </div>
                <div data-aos="fade"
                 data-aos-duration="2000">
                <ContactForm></ContactForm>
                <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

MapFormTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default MapFormTemplate
